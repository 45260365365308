import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
      "https://api.lndg.page/campaigns/6690133/lists/1146636/entries?widget_id=97713013"
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <h1>2023 Nose of Tralee Leaderboard</h1>
        <table>
          <tr>
            <th>Dog</th>
            <th>Score</th>
          </tr>
          {data
            .sort((a, b) => {
              return b.votes[0] - a.votes[0];
            })
            .map((value, index) => {
              return (
                <tr key={index}>
                  <td>{value.title}</td>
                  <td>{value.votes[0]}</td>
                </tr>
              );
            })}
        </table>
      </header>
    </div>
  );
}

export default App;
